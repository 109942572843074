import { InfiniteMovingCards } from "../ui/skills-moving-cards.tsx";

export default function About() {
    return (
        <section id="oMne" class="bg2 flex flex-row flex-wrap w-full content-start">
            <div class="main z-50">
                <div class="flex z-20 flex-col w-full items-center gap-y-3 pb-7">
                    <span class="text-purple uppercase font-bold text-sm">skillset</span>
                    <h3 class="text-white text-4xl">Skvěle ovládám</h3>
                </div>
                <InfiniteMovingCards items={skills} direction="left" speed="normal" />
            </div>
            <div class="main flex z-20 flex-col w-full items-center gap-y-3 !pt-0">
                <span class="text-purple uppercase font-bold text-sm">Dovednosti</span>
                <h3 class="text-white text-4xl pb-7">Specializuji se na</h3>
                <div class="grid sm:grid-cols-2 lg:grid-cols-2 items-center gap-6 md:gap-10">
                    <div class="size-full bg-white shadow-lg rounded-lg p-5">
                        <div class="flex items-center gap-x-4 mb-3">
                            <div class="inline-flex justify-center items-center size-[62px] rounded-full text-white bg-purple hover:bg-purple/70">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                </svg>
                            </div>
                            <div class="flex-shrink-0">
                                <h3 class="block text-lg font-semibold text-black">Webové stránky</h3>
                            </div>
                        </div>
                        <p class="text-black/95">Veškeré webové prezentace na míru klienta. Na základě důkladné analýzy požadavků a potřeb.</p>
                    </div>
                    <div class="size-full bg-white shadow-lg rounded-lg p-5">
                        <div class="flex items-center gap-x-4 mb-3">
                            <div class="inline-flex justify-center items-center size-[62px] rounded-full bg-purple hover:bg-purple/70 text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.098 19.902a3.75 3.75 0 0 0 5.304 0l6.401-6.402M6.75 21A3.75 3.75 0 0 1 3 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 0 0 3.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008Z" />
                                </svg>
                            </div>
                            <div class="flex-shrink-0">
                                <h3 class="block text-lg font-semibold text-black">Grafické práce</h3>
                            </div>
                        </div>
                        <p class="text-black/95">Digitální a tištěné grafické práce včetně odeslání na adresu.</p>
                    </div>
                    <div class="size-full bg-white shadow-lg rounded-lg p-5">
                        <div class="flex items-center gap-x-4 mb-3">
                            <div class="inline-flex justify-center items-center size-[62px] rounded-full bg-purple hover:bg-purple/70 text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                                </svg>
                            </div>
                            <div class="flex-shrink-0">
                                <h3 class="block text-lg font-semibold text-black">Video/Foto</h3>
                            </div>
                        </div>
                        <p class="text-black/95">Foto a video od produktů až po celkovou prezentaci Vaší firmy.</p>
                    </div>
                    <div class="size-full bg-white shadow-lg rounded-lg p-5">
                        <div class="flex items-center gap-x-4 mb-3">
                            <div class="inline-flex justify-center items-center size-[62px] rounded-full bg-purple hover:bg-purple/70 text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42" />
                                </svg>
                            </div>
                            <div class="flex-shrink-0">
                                <h3 class="block text-lg font-semibold text-black">Branding</h3>
                            </div>
                        </div>
                        <p class="text-black/95">Branding od loga až po kompletní identitu značky.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

const skills = [
    {
        name: "aftereffects",
    },
    {
        name: "illustrator",
    },
    {
        name: "indesign",
    },
    {
        name: "lightroom",
    },
    {
        name: "photoshop",
    },
    {
        name: "premiere",
    },
    {
        name: "wordpress",
    },
    {
        name: "elementor",
    },
    {
        name: "chatgpt",
    },
]