import Hero from "../components/Home/Hero"
import About from "../components/Home/About"
import MyWork from "../components/Home/MyWork";
import MyProjects from "../components/Home/MyProjects";
import Contact from "../components/Contact";



export default function Home() {

    return (
        <main>
            <Hero />
            <About />
            <MyWork />
            <MyProjects />
            <Contact />
        </main>
    );
}