"use client";
import React from "react";
import { PinContainer } from "../ui/3d-pin.tsx";
 
export default function MyWork() {
  return (
    <section id="prace" class="bg-black">
        <div class="main !pt-[85px] !pb-[118px]">
            <div class="flex z-20 flex-col w-full items-center gap-y-3 pb-7">
                <span class="text-purple uppercase font-bold text-sm">práce</span>
                <h5 class="text-white text-4xl">Podívejte se na hotové realizace</h5>
            </div>
            <div class="flex flex-row flex-wrap w-full justify-center lg:justify-between gap-24 mt-[5px]">
                <div className="flex items-center justify-center w-full md:w-auto">
                    <PinContainer
                        title="jiricapek.cz"
                        href="https://www.jiricapek.cz"
                    >
                        <a href="https://www.jiricapek.cz" target="_blank">
                            <div className="flex basis-full flex-col tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] h-[14rem] justify-center">
                                <img src="./assets/logos/jiricapek.webp" />
                            </div>
                            </a>
                    </PinContainer>
                </div>
                <div className=" flex items-center justify-center w-full md:w-auto">
                    <PinContainer
                        title="matama.cz"
                        href=""
                    >
                        <a href="https://www.matama.cz" target="_blank">
                            <div className="flex basis-full flex-col tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] h-[14rem] justify-center">
                                <img src="./assets/logos/matama.webp" />
                                </div>
                            </a>
                    </PinContainer>
                </div>
                <div className=" flex items-center justify-center w-full md:w-auto">
                    <PinContainer
                        title="baroko.org"
                        href=""
                    >
                        <a href="https://www.baroko.org" target="_blank">
                            <div className="flex basis-full flex-col tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] h-[14rem] justify-center">
                                <img src="./assets/logos/baroko.webp" />
                            </div>
                            </a>
                    </PinContainer>
                </div>
                <div className=" flex items-center justify-center w-full md:w-auto">
                    <PinContainer
                        title="solarflow.cz"
                        href="https://www.solarflow.cz"
                    >
                        <a href="https://www.solarflow.cz" target="_blank">
                            <div className="flex basis-full flex-col tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] h-[14rem] justify-center">
                                <img src="./assets/logos/solarflow.webp" />
                            </div>
                        </a>
                    </PinContainer>
                </div>
                <div className=" flex items-center justify-center w-full md:w-auto">
                    <PinContainer
                        title="amfels.cz"
                        href="https://www.amfels.cz"
                    >
                        <a href="https://www.amfels.cz" target="_blank">
                            <div className="flex basis-full flex-col tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] h-[14rem] justify-center">
                                <img src="./assets/logos/amfels.webp" />
                            </div>
                        </a>
                    </PinContainer>
                </div>
                <div className=" flex items-center justify-center w-full md:w-auto">
                    <PinContainer
                        title="alfain.de"
                        href="https://alfain.de"
                    >
                        <a href="https://www.alfain.de" target="_blank">
                            <div className="flex basis-full flex-col tracking-tight text-slate-100/50 sm:basis-1/2 w-[20rem] h-[14rem] justify-center">
                                <img src="./assets/logos/alfain.webp" />
                            </div>
                        </a>
                    </PinContainer>
                </div>
            </div>
        </div>
    </section>
  );
}