function Contact() {
    return (
        <section id="kontakt">
            <div class="flex flex-col w-full gap-y-20 main">
                <div class="flex z-20 flex-col w-full items-center gap-y-3">
                    <span class="text-purple uppercase font-bold text-sm">Kontakt</span>
                    <h5 class="text-white text-4xl text-center">Ozvěte se mi na mobil nebo mail</h5>
                </div>
                <div class="flex flex-col items-center">
                    <span class="text-white text-3xl">+420 604 798 369</span>
                    <span class="text-white text-3xl">sedlacek_m@centrum.cz</span>
                </div>
            </div>
        </section>
    );
}

export default Contact;