import React from "react";

function Footer() {
    const rok = new Date().getFullYear();
    return (
            <footer class="main flex !pb-0 !pt-0 flex-col gap-y-7">
                    <div class=" flex flex-row w-full bg-black items-center py-7 justify-center">
                        <h5 class="text-white text-sm font-bold uppercase">© {rok} Martin Sedláček | <span id="footerLogo">△</span></h5>
                    </div>
            </footer>
    );
}

export default Footer;