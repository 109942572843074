import Navbar from "./components/Navbar";
import Routing from "./Routing";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App font-roboto bg-black">
      <Navbar />
    	<Routing />
      <Footer />
    </div>
  );
}

export default App;
