import main from "../../assets/main";

export default function Hero() {
    return(
    <section id="hero" class="section-first flex flex-col">
      <div class="main flex flex-col h-full justify-center gap-y-10">
          <h1 class="text-3xl md:text-[5.5rem] w-[49%] text-white uppercase font-bold">Sedláček Martin</h1>
          <h2 class="text-xl md:text-3xl text-white uppercase"><span class="rewrite">digitální kreativec.</span></h2>
          <div class="flex gap-x-7">
            <a href="#prace" class="btn btn-medium btn-primary w-28">Práce</a>
            <a href="#kontakt" class="btn btn-medium btn-secondary">Kontakt</a>
          </div>
      </div>
    </section>
    );
}