function Navbar() {
    return (
    <header class="flex flex-wrap content-center sticky top-0 z-[9999] shadow-md bg-black/45 w-full h-28 backdrop-saturate-100 backdrop-blur-sm">
      <div class="flex flex-col md:flex-row justify-between main mx-auto items-center gap-y-3"> 
          <a href="#hero">
          <img src="./assets/logo.png" className="w-20 h-20"/>
          </a>
          <div class="flex gap-x-1 sm:gap-x-3 lg:gap-x-7 text-white font-semibold uppercase text-xs md:text-lg xl:text-3xl">
            <a href="#oMne" class="hover:text-purple text-nowrap">O mně<span class="text-purple">.</span></a>
            <a href="#prace" class="hover:text-purple text-nowrap">Práce<span class="text-purple">.</span></a>
            <a href="#projekty" class="hover:text-purple text-nowrap">Projekty<span class="text-purple">.</span></a>
            <a href="#kontakt" class="hover:text-purple text-nowrap">Kontakt<span class="text-purple">.</span></a>
          </div>
      </div>
  </header>
    );
}

export default Navbar;