import $ from "jquery";

var slova = ["digitální kreativec.", "webdesigner.", "ideamaker."];
var slovo = slova[0];
var j = 0;
var count = slova.length;
var tick = 2000;
var i = slovo.length, max = slovo.length;
var fase = 1;
var myFunction = function(){
	clearInterval(interval);
	stopBarAnimation();
	tick = 125;
	if(fase == 0){
		i++;
		if(i==max){
			barAnimation(300);
			tick = 2000;
			fase = 1;
		}
	} else {
		i--;
		if(i==0){
			barAnimation(300);
			tick = 1500;
			j++;
			slovo = slova[j%count];
			max = slovo.length;
			fase = 0;
		}
	}
	$(".rewrite").text(slovo.substring(0,i));
  interval = setInterval(myFunction, tick);
}
var barInterval;
function stopBarAnimation(){
	clearInterval(barInterval);
	$(".rewrite").css("border-right", "3px solid #3500D3");
}
function barAnimation(tick, counter = 0){
			barInterval = setInterval(function(){
				if(counter%2){
					$(".rewrite").css("border-right", "3px solid transparent");
				}else{
					$(".rewrite").css("border-right", "3px solid #3500D3");
				}
				counter++;
			}, tick);
}
var interval = setInterval(myFunction, tick);
barAnimation(300);